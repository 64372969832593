import { fetchWrapper } from "src/helpers";

const baseUrl = "/api/powerbi";

const get = async () => {
  return await fetchWrapper.post(baseUrl);
};

export const powerbiService = {
  get,
};
