import { AppUserObject, fetchWrapper } from "src/helpers";
import { graphqlApiCall } from "./graphql.service";

const baseUrl = "/api/app_users";
type AppUser = {
  userId: string;
  name: string;
  email: string;
  mobileNumber: string;
  isFree: boolean;
  isBlocked: boolean;
  isDeleted: boolean;
  isBeta: boolean;
  dashboardAccess: boolean;
};

const getAll = async () => {
  const columns = [
    "userId",
    "name",
    "email",
    "mobileNumber",
    "isFree",
    "isBlocked",
    "isDeleted",
    "isBeta",
    "dashboardAccess",
  ];
  const response = await graphqlApiCall({ node: "allUsers", columns });
  return response.map((data: AppUser) => {
    return {
      user_id: data.userId,
      name: data.name,
      email: data.email,
      mobile_number: data.mobileNumber,
      is_free: data.isFree,
      is_blocked: data.isBlocked,
      is_deleted: data.isDeleted,
      is_beta: data.isBeta,
      dashboard_access: data.dashboardAccess,
    };
  });
};
const update = async (url: string, body: AppUserObject) => {
  return await fetchWrapper.post(baseUrl + url, body);
};

export const appUserService = {
  getAll,
  update,
};
