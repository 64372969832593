import { userService } from "src/services";
import {
  AppUserObject,
  DashboardObject,
  CreateHpsObj,
  TrendingSectorObj,
  UserObject,
  AppNotifyObj,
  FeatureFlagObj,
  CreateFnOObj,
} from "./table-objects";
import { User } from "firebase/auth";

interface RequestOptions {
  method: string;
  headers: { Authorization?: string; "Content-Type"?: string };
  body?: string;
}
const request = (method: string) => {
  return async (
    url: string,
    body?:
      | User
      | UserObject
      | DashboardObject
      | AppUserObject
      | AppNotifyObj
      | CreateHpsObj
      | CreateFnOObj
      | FeatureFlagObj[]
      | TrendingSectorObj[],
  ) => {
    const requestOptions: RequestOptions = {
      method,
      headers: authHeader(),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
  };
};

const authHeader = () => {
  // return auth header with jwt if user is logged in and request is to the api url
  const user = userService.adminValue;
  const isLoggedIn = user?.token;
  if (isLoggedIn) {
    return { Authorization: `Bearer ${user.token}` };
  } else {
    return {};
  }
};

const handleResponse = async (response: Response) => {
  const isJson = response.headers
    ?.get("content-type")
    ?.includes("application/json");
  const data = isJson ? await response.json() : null;
  // check for error response
  if (!response.ok) {
    if ([401, 403].includes(response.status) && userService.adminValue) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      userService.logout();
    }

    // get error message from body or default to response status
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
};

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};
