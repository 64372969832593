import { BehaviorSubject } from "rxjs";
import Router from "next/router";
import { UserObject, fetchWrapper } from "src/helpers";
import { alertService } from "src/services";
import { graphqlApiCall } from "./graphql.service";
import { User } from "firebase/auth";

const adminBaseUrl = "/api/admin";
const baseUrl = "/api/users";

const adminSubject = new BehaviorSubject(
  typeof window !== "undefined" &&
    localStorage.getItem("admin") !== null &&
    JSON.parse(localStorage.getItem("admin") || ""),
);
const userSubject = new BehaviorSubject(
  typeof window !== "undefined" &&
    localStorage.getItem("user") !== null &&
    JSON.parse(localStorage.getItem("user") || ""),
);

const columns = ["id", "firstName", "lastName", "username", "isAdmin"];

const userLogin = async (params: User) => {
  const user = await fetchWrapper.post(`${baseUrl}/register`, params);

  const userValue = { token: user.token };
  userSubject.next(userValue);
  localStorage.setItem("user", JSON.stringify(userValue));
};

const adminLogin = async (
  username: string,
  password: string,
  isRemember: boolean,
) => {
  const user = await fetchWrapper.post(`${adminBaseUrl}/authenticate`, {
    firstName: "",
    lastName: "",
    username,
    password,
  });

  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  const userValue = { id: user.id, is_admin: user.is_admin, token: user.token };
  adminSubject.next(userValue);
  localStorage.setItem("admin", JSON.stringify(userValue));
};

const logout = () => {
  alertService.clear();
  // remove user from local storage, publish null to user subscribers and redirect to login page
  localStorage.removeItem("user");
  localStorage.removeItem("admin");
  userSubject.next(null);
  adminSubject.next(null);
  Router.push("/");
};

const register = async (user: UserObject) => {
  await fetchWrapper.post(`${adminBaseUrl}/register`, user);
};

const getAll = async () => {
  const response = await graphqlApiCall({ node: "allAdmins", columns });
  return response.map((data: Record<string, string>) => {
    return {
      ...data,
      is_admin: data.isAdmin,
    };
  });
};

const getById = async (id: string) => {
  const response: UserObject = await graphqlApiCall({
    node: "adminById",
    columns,
    id,
  });
  return response;
};

const update = async (id: string, params: UserObject) => {
  await fetchWrapper.put(`${adminBaseUrl}/${id}`, params);

  // update stored user if the logged in user updated their own record
  if (id === adminSubject.value.id) {
    // update local storage
    const user = { ...adminSubject.value, ...params };
    // localStorage.setItem('user', JSON.stringify(user));

    // publish updated user to subscribers
    adminSubject.next(user);
  }
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = async (id: string) => {
  await fetchWrapper.delete(`${adminBaseUrl}/${id}`);

  // auto logout if the logged in user deleted their own record
  if (id === adminSubject.value.id) {
    logout();
  }
};

export const userService = {
  user: adminSubject.asObservable(),
  get adminValue() {
    return adminSubject.value;
  },
  get userValue() {
    return userSubject.value;
  },
  userLogin,
  adminLogin,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
};
